import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Config from "./config";

const route = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/config",
    element: <Config />,
  },
]);

const Routes = (children) => {
  return <RouterProvider router={route}>{children}</RouterProvider>;
};

export default Routes;
