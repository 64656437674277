import axios from "axios";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Navbar,
  Row,
  Table,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useUserStore } from "./store";

const Config = () => {
  const [companyData, setCompanyData] = useState([]); // Store company data
  const [initValues, setInitValues] = useState({
    _id: "",
    companyName: "",
    uuid: "",
    isActive: true,
  });
  const [key, setKey] = useState(Math.random());
  const navigate = useNavigate();
  const userData = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  // Check if user is authenticated, if not redirect to login
  useEffect(() => {
    if (!userData.isAuth) {
      navigate("/login");
    }
  }, [navigate, userData.isAuth]);

  // Fetch company data from the API
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          "https://getqr.amitaujas.com/api/company"
        );
        setCompanyData(response.data.data); // Assuming the data is inside 'data' field in the response
      } catch (error) {
        handleApiError(error); // Handle errors by showing a toast notification
      }
    })();
  }, []);

  const handleApiError = (error) => {
    if (error.response) {
      toast.error(error.response.data.message);
    } else if (error.request) {
      toast.error(error.request);
    } else {
      toast.error(error.message);
    }
  };

  const getCompanyData = async () => {
    try {
      const response = await axios.get(
        "https://getqr.amitaujas.com/api/company"
      );
      setCompanyData(response.data.data); // Update state with the new data
    } catch (error) {
      handleApiError(error);
    }
    setKey(Math.random()); // Trigger re-render
  };

  const handleSubmit = async (values) => {
    try {
      if (values._id) {
        // Update existing company data
        const response = await axios.post(
          `https://getqr.amitaujas.com/api/company/${values._id}`,
          values
        );
        if (response.data.success) {
          toast.success(response.data.message);
          getCompanyData(); // Refresh data after successful update
        } else {
          toast.error(response.data.message);
        }
      } else {
        // Add new company data
        const response = await axios.post(
          "https://getqr.amitaujas.com/api/company",
          values
        );
        if (response.data.success) {
          toast.success(response.data.message);
          getCompanyData(); // Refresh data after successful creation
        } else {
          toast.error(response.data.message);
        }
      }

      // Reset form after successful submission
      setInitValues({
        _id: "",
        companyName: "",
        uuid: "",
        isActive: true,
      });
      setKey(Math.random());
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              background: "red",
              color: "black",
            },
          },
        }}
      />

      <Navbar bg="dark" data-bs-theme="dark" expand={"lg"}>
        <Container>
          <Navbar.Brand href="/">ZATCA QR</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                onClick={() => {
                  setUser({ isAuth: false, jwt: "" });
                  navigate("/login");
                }}
              >
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container>
        <div style={{ margin: "1rem" }}>
          <Card>
            <Card.Header>
              <div>
                <h5>ADD COMPANY</h5>
              </div>
            </Card.Header>

            <Card.Body>
              <Formik
                initialValues={initValues}
                validationSchema={Yup.object().shape({
                  companyName: Yup.string().required(
                    "Please enter company name"
                  ),
                  uuid: Yup.string().required("Please enter UUID"),
                  isActive: Yup.boolean().oneOf([true, false]),
                })}
                onSubmit={handleSubmit}
                key={key}
                enableReinitialize
              >
                {({ errors, handleChange, handleSubmit, touched, values }) => {
                  return (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                          <Form.Group className="mb-3" controlId="name">
                            <Form.Label>
                              Company NAME{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              value={values.companyName}
                              name="companyName"
                              type="text"
                              maxLength="100"
                              isInvalid={
                                !!touched.companyName && !!errors.companyName
                              }
                              onChange={handleChange}
                            />
                            <Form.Text className="text-danger">
                              {errors.companyName || ""}
                            </Form.Text>
                          </Form.Group>
                        </Col>

                        <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                          <Form.Group className="mb-3" controlId="uuid">
                            <Form.Label>
                              UUID <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              value={values.uuid}
                              name="uuid"
                              type="text"
                              isInvalid={!!touched.uuid && !!errors.uuid}
                              onChange={handleChange}
                            />
                            <Form.Text className="text-danger">
                              {errors.uuid || ""}
                            </Form.Text>
                          </Form.Group>
                        </Col>

                        <Col
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Form.Group className="mb-3" controlId="isActive">
                            <Form.Check
                              type={"checkbox"}
                              label={`Is Active`}
                              name="isActive"
                              onChange={handleChange}
                              value={values.isActive}
                              defaultChecked={values.isActive}
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button type="submit">
                              {values._id ? "UPDATE" : "ADD"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Card.Body>
          </Card>
        </div>

        <br />

        <div style={{ margin: "1rem" }}>
          <Card>
            <Card.Header>
              <div>
                <h5>VIEW COMPANY</h5>
              </div>
            </Card.Header>

            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>SR. NO.</th>
                    <th>Company NAME</th>
                    <th>UUID</th>
                    <th>IS ACTIVE</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {companyData.map((item, index) => (
                    <tr key={item._id}>
                      <td>{index + 1}</td>
                      <td>{item?.companyName || ""}</td>
                      <td>{item?.uuid || ""}</td>
                      <td>{item.isActive ? "Yes" : "No"}</td>
                      <td>
                        <div style={{ display: "flex", gap: "1rem" }}>
                          <Button
                            onClick={() => {
                              setInitValues({
                                ...item,
                              });

                              setKey(Math.random());
                            }}
                          >
                            UPDATE
                          </Button>

                          <Button
                            variant="danger"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  await axios
                                    .delete(
                                      `https://getqr.amitaujas.com/api/company/${item._id}`
                                    )
                                    .then((res) => res.data)
                                    .then((res) => {
                                      if (res.success) {
                                        Swal.fire({
                                          title: "Deleted!",
                                          text: "Company has been deleted.",
                                          icon: "success",
                                        });

                                        getCompanyData(); // Refresh data after deletion
                                      } else {
                                        toast.error(res.message || "");
                                      }
                                    })
                                    .catch(handleApiError);
                                }
                              });
                            }}
                          >
                            DELETE
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Config;
